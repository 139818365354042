const SolutionsList = () => import('./components/SolutionsList')
const SolutionsManage = () => import('./components/SolutionsManage')
const SolutionsOffers = () => import('./components/SolutionsOffers')
const SolutionsConfig = () => import('./components/SolutionsConfig')
const SolutionsTypes = () => import('./components/SolutionsTypes')
const SolutionsTypesManage = () => import('./components/SolutionsTypesManage')

export default [
  {
    name: 'solutions.index',
    path: '/solutions/list',
    component: SolutionsList,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'solution',
        access: 'write'
      }
    }
  },
  {
    name: 'solutions.types',
    path: '/solutions/types',
    component: SolutionsTypes,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'solution',
        access: 'write'
      }
    }
  },
  {
    name: 'solutions.offers',
    path: '/solutions/offers',
    component: SolutionsOffers,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'solution',
        access: 'write'
      }
    }
  },
  {
    name: 'solutions.manage',
    path: '/solutions/manage/:id?',
    component: SolutionsManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'solution',
        access: 'write'
      }
    }
  },
  {
    name: 'solutions.types.manage',
    path: '/solutions/types/manage/:id?',
    component: SolutionsTypesManage,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'solution',
        access: 'write'
      }
    }
  },
  {
    name: 'solutions.config',
    path: '/solutions/offers/config/:id',
    component: SolutionsConfig,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'solution',
        access: 'write'
      }
    }
  }
]
