import { http } from '@/support/http'
import { createQuery } from '../../../support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export const getSolutionsList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/solutions?${queryString}`)
}

export const getSolutionsSearch = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/solutions/search?${queryString}`)
}

export const getSolution = (idSolution) => {
  return http.get(`api/solution/${idSolution}`)
}

export const getCertificatesList = (pagination) => {
  pagination.limit = 1000
  const queryString = createQuery(pagination)

  return http.get(`api/solution/certificates?${queryString}`)
}

export const getSurveysList = (pagination) => {
  pagination.limit = 1000
  const queryString = createQuery(pagination)

  return http.get(`api/solution/surveys?${queryString}`)
}

export const getThemesList = (pagination) => {
  pagination.limit = 1000
  const queryString = createQuery(pagination)

  return http.get(`showcase/themes?${queryString}`)
}

export const getGroupsList = (pagination) => {
  pagination.limit = 1000
  const queryString = createQuery(pagination)

  return http.get(`api/groups?${queryString}`)
}

export const getTargetAudiencesList = (pagination) => {
  pagination.limit = 1000
  const queryString = createQuery(pagination)

  return http.get(`showcase/target_audience?${queryString}`)
}

export const getLearningPathsList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`showcase/learning_paths?${queryString}`)
}

export const getThematicPagesList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`showcase/thematic_pages?${queryString}`)
}

// export const getSolutionsTypesList = (pagination) => {
//   const queryString = createQuery(pagination)

//   return http.get(`showcase/solutions/types?${queryString}`)
// }

export const getSolutionsTypesList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/solution_types?${queryString}`)
}

export const getSasUnityList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/sas/unity?${queryString}`)
}

export const getSasActionList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/sas/actions?${queryString}`)
}

export const getSasProjectList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/sas/projects?${queryString}`)
}

export const getProviderList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/financial/providers/types?${queryString}`)
}

export const getRmProviderList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/rm/providers?${queryString}`)
}

export const saveSolution = ({ data }) => {
  const form = parseToFormDataWithArray(data)

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  if (data.card_image && typeof data.card_image === 'object') {
    form.append('card_image', data.card_image, data.card_image.name)
  }

  if (data.file && data.file.length > 0) {
    form.append('file', data.file[0], data.file[0].name)
  }

  if (data.podcast_file && data.podcast_file.length > 0) {
    form.append('podcast_file', data.podcast_file[0], data.podcast_file[0].name)
  }

  return http.post('api/solution', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateSolution = ({ id, data }) => {
  const form = parseToFormDataWithArray(data)

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  if (data.card_image && typeof data.card_image === 'object') {
    form.append('card_image', data.card_image, data.card_image.name)
  }

  if (data.file && data.file.length > 0) {
    form.append('file', data.file[0], data.file[0].name)
    form.delete('file_id')
  }

  if (data.podcast_file && data.podcast_file.length > 0) {
    form.append('podcast_file', data.podcast_file[0], data.podcast_file[0].name)
  }

  return http.post(`api/solution/${id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const removeSolution = (idSolution) => {
  return http.delete(`api/solution/${idSolution}`)
}

export const toggleActiveSolution = (idSolution) => {
  return http.post(`api/solution/${idSolution}/toggle`)
}

export const getCancellationList = () => {
  return http.get('api/cancellation_reason')
}

export const getSolutionAsset = (idAsset) => {
  return http.get(
    `api/assets/${idAsset}/file`,
    {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache'
      },
      data: {}
    }
  )
}

export const getVimeoDetails = (url) => {
  return http.get(`https://vimeo.com/api/oembed.json?url=${url}`)
}

export const getSolutionsForComboBox = () => {
  return http.get('api/solutionsForComboBox')
}

export const getSolutionTypeIntegration = (solutionType) => {
  return http.get(`api/solution/getSolutionTypeIntegration/${solutionType}`)
}

export const getSolutionEndRules = (solutionTypeId) => {
  return http.get(`api/solution_type/configurations/${solutionTypeId}`)
}

export const updateSolutionEndRules = (data) => {
  return http.post('api/solutions/configurations', data)
}

export const validatePortfolio = (idPortfolio) => {
  return http.post(`api/solution/validatePortfolio/${idPortfolio}`)
}

export const toggleActiveSolutionType = (idSolutionType) => {
  return http.put(`api/solution_type/${idSolutionType}/toggle`)
}

export const getOffersList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/offers?${queryString}`)
}

export const getOffer = (offerId) => {
  return http.get(`api/offer/${offerId}`)
}

export const getIntegrations = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/solution/integrations?${queryString}`)
}

export const getSolutionType = (id) => {
  return http.get(`api/solution_type/${id}`)
}

export const saveSolutionType = (data) => {
  return http.post('api/solution_type', data.data)
}

export const updateSolutionType = ({ id, data }) => {
  return http.put(`api/solution_type/${id}`, data)
}
